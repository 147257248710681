import React from 'react';
export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper jrhtw9ph4a-editor_css' },
  page: { className: 'home-page l1et397jjm-editor_css' },
  logo: {
    className: 'header2-logo l1est5zaj6e-editor_css',
    children:
      'https://xhm-test.oss-cn-beijing.aliyuncs.com/xhm_img/fyjc/logo.jpg',
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav',
        to: 'Content4_0',
        children: '企业品牌',
        className: 'l1etzupuj1n-editor_css menu-item',
      },
      {
        name: 'linkNav~l1frqhbp1ti',
        to: 'Content8_0',
        children: '服务项目',
        className: 'menu-item',
      },
      {
        name: 'linkNav~l1frqqtvud',
        to: 'Teams1_0',
        children: '关于我们',
        className: 'menu-item',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
  Menu: {
    children: [
      {
        name: 'Banner3_0',
        to: 'Banner3_0',
        children: '首页',
        className: 'active menu-item',
      },
      {
        name: 'Content8_0',
        to: 'Content8_0',
        children: '特邀嘉宾',
        className: 'menu-item',
      },
      {
        name: 'Content9_0',
        to: 'Content9_0',
        children: '会议日程',
        className: 'menu-item',
      },
      {
        name: 'Content10_0',
        to: 'Content10_0',
        children: '大会地址',
        className: 'menu-item',
      },
      {
        name: 'Content11_0',
        to: 'Content11_0',
        children: '展台展示',
        className: 'menu-item',
      },
      {
        name: 'Content12_0',
        to: 'Content12_0',
        children: '特别鸣谢',
        className: 'menu-item',
      },
    ],
  },
};
export const Banner30DataSource = {
  wrapper: { className: 'banner3' },
  textWrapper: {
    className: 'banner3-text-wrapper l1et7uk326h-editor_css',
    children: [
      {
        name: 'slogan',
        className: 'banner3-slogan',
        children: '用科技的力量让生活更美好',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner3-name',
        children: (
          <span>
            <span>
              <p>Make the life better</p>
            </span>
          </span>
        ),
      },
      {
        name: 'time',
        className: 'banner3-time l1ethneipc7-editor_css',
        children: (
          <span>
            <p>中国·上海</p>
          </span>
        ),
      },
    ],
  },
};
export const Content80DataSource = {
  wrapper: {
    className: 'home-page-wrapper content8-wrapper l1fv7kuf6e-editor_css',
  },
  page: { className: 'home-page content8' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>检测服务</p>
          </span>
        ),
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'content-wrapper l1fvpheccrr-editor_css',
    children: [
      {
        name: 'block0',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper l1fv4k8llkh-editor_css',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://xhm-test.oss-cn-beijing.aliyuncs.com/xhm_img/fyjc/product/WechatIMG1304.jpeg',
          },
          title: {
            className: 'content8-title',
            children: (
              <span>
                <span>
                  <p>生理刻画（全套）</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content8-content',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>
                        <br />
                      </p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://xhm-test.oss-cn-beijing.aliyuncs.com/xhm_img/fyjc/product/WechatIMG624.png',
          },
          title: {
            className: 'content8-title',
            children: (
              <span>
                <p>肿瘤早筛（7项）</p>
              </span>
            ),
          },
          content: {
            className: 'content8-content',
            children: (
              <span>
                <p>
                  <br />
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://xhm-test.oss-cn-beijing.aliyuncs.com/xhm_img/fyjc/product/WechatIMG623.jpeg',
          },
          title: {
            className: 'content8-title',
            children: (
              <span>
                <p>抗肿瘤免疫活性</p>
              </span>
            ),
          },
          content: {
            className: 'content8-content',
            children: (
              <span>
                <p>
                  <br />
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://xhm-test.oss-cn-beijing.aliyuncs.com/xhm_img/fyjc/product/WechatIMG621.png',
          },
          title: {
            className: 'content8-title',
            children: (
              <span>
                <p>过敏反应水平</p>
              </span>
            ),
          },
          content: {
            className: 'content8-content',
            children: (
              <span>
                <p>
                  <br />
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://xhm-test.oss-cn-beijing.aliyuncs.com/xhm_img/fyjc/product/src%3Dhttp---www.sanyuanxing.cn-kindeditor-attached-image-20180904-20180904173422_45395.jpg%26refer%3Dhttp---www.sanyuanxing.cn%26app%3D2002%26size%3Df9999%2C10000%26q%3Da80%26n%3D0%26g%3D0n%26fmt%3Djpeg.jpg',
          },
          title: {
            className: 'content8-title',
            children: (
              <span>
                <span>
                  <p>衰老状态</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content8-content',
            children: (
              <span>
                <p>
                  <br />
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://xhm-test.oss-cn-beijing.aliyuncs.com/xhm_img/fyjc/product/src%3Dhttp---image.welian.com-bvdy1540427114357_2000_1333_335.jpeg%26refer%3Dhttp---image.welian.com%26app%3D2002%26size%3Df9999%2C10000%26q%3Da80%26n%3D0%26g%3D0n%26fmt%3Djpeg.jpg',
          },
          title: {
            className: 'content8-title',
            children: (
              <span>
                <p>毛发状态</p>
              </span>
            ),
          },
          content: {
            className: 'content8-content',
            children: (
              <span>
                <p>
                  <br />
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block6',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://xhm-test.oss-cn-beijing.aliyuncs.com/xhm_img/fyjc/product/src%3Dhttp---5b0988e595225.cdn.sohucs.com-images-20191219-0731424e25b3472d8735e6dc4129f31a.jpeg%26refer%3Dhttp---5b0988e595225.cdn.sohucs.com%26app%3D2002%26size%3Df9999%2C10000%26q%3Da80%26n%3D0%26g%3D0n%26fmt%3Djpeg.jpg',
          },
          title: {
            className: 'content8-title',
            children: (
              <span>
                <p>神经衰弱风险</p>
              </span>
            ),
          },
          content: {
            className: 'content8-content',
            children: (
              <span>
                <p>
                  <br />
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block7',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://xhm-test.oss-cn-beijing.aliyuncs.com/xhm_img/fyjc/product/WechatIMG1308.jpeg',
          },
          title: {
            className: 'content8-title',
            children: (
              <span>
                <p>营养不良风险</p>
              </span>
            ),
          },
          content: {
            className: 'content8-content',
            children: (
              <span>
                <p>
                  <br />
                </p>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Feature80DataSource = {
  wrapper: { className: 'home-page-wrapper feature8-wrapper' },
  page: { className: 'home-page feature8' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature8-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature8-title-h1',
        children: (
          <span>
            <p>检测流程</p>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'feature8-title-content',
        children: '流程简单清晰，快速响应需求',
      },
    ],
  },
  childWrapper: {
    className: 'feature8-button-wrapper',
    children: [
      {
        name: 'button',
        className: 'feature8-button',
        children: {
          href: 'https://fyjc.xiaohemu.net/user/',
          children: '立即体验',
          target: '_blank',
        },
      },
    ],
  },
  Carousel: {
    dots: false,
    className: 'feature8-carousel',
    wrapper: { className: 'feature8-block-wrapper' },
    children: {
      className: 'feature8-block',
      titleWrapper: {
        className: 'feature8-carousel-title-wrapper',
        title: { className: 'feature8-carousel-title' },
      },
      children: [
        {
          name: 'block0',
          className: 'feature8-block-row l1g0jo5058l-editor_css',
          gutter: 120,
          title: {
            className: 'feature8-carousel-title-block',
            children: '平台自主训练流程',
          },
          children: [
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://xhm-test.oss-cn-beijing.aliyuncs.com/xhm_img/fyjc/product/%E5%9B%BE%E7%89%8718.png',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <span>
                          <p>客户端选择服务</p>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children: (
                      <span>
                        <p>使用微信公众号选择对应的服务类目</p>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://xhm-test.oss-cn-beijing.aliyuncs.com/xhm_img/fyjc/product/%E5%9B%BE%E7%89%8719.png',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <p>样本采集</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children: (
                      <span>
                        <p>采集过程简单，部分可邮寄样本</p>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child2',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://xhm-test.oss-cn-beijing.aliyuncs.com/xhm_img/fyjc/product/%E5%9B%BE%E7%89%8716.png',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <span>
                          <span>
                            <p>样本处理和检测</p>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children: (
                      <span>
                        <span>
                          <p>
                            样本登记及预处理，上机检测，数据分析，保证数据准确性
                          </p>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child3',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://xhm-test.oss-cn-beijing.aliyuncs.com/xhm_img/fyjc/product/%E5%9B%BE%E7%89%8717.png',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <span>
                          <p>云端报告生成</p>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children: (
                      <span>
                        <p>
                          生成云端报告，专家进行解读，与客户沟通交流解答疑问
                        </p>
                      </span>
                    ),
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  },
};
export const Content40DataSource = {
  wrapper: { className: 'home-page-wrapper content4-wrapper' },
  page: { className: 'home-page content4' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>企业介绍</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content content4-title-content l1fvrkqsf-editor_css',
        children: (
          <span>
            <span>
              <p>
                上海复彦生物科技有限公司是一家为客户提供生命健康检测与评估服务的新兴企业。公司坚持自主创新和成果转化，依托于医学检测平台，立足新一代蛋白质组技术，基于大数据驱动、从而对客户的健康状况进行评估，旨在将生命科学、临床医学及数据挖掘等创新技术融为一体，推出全新的健康评估模式服务于人类健康。
              </p>
            </span>
          </span>
        ),
      },
    ],
  },
  video: {
    className: 'content4-video',
    children: {
      video:
        'https://xhm-test.oss-cn-beijing.aliyuncs.com/xhm_img/fyjc/teammember/%E8%A7%86%E9%A2%911%EF%BC%9A%E5%A4%8D%E6%97%A6%E5%A4%A7%E5%AD%A6P3%E5%AE%9E%E9%AA%8C%E5%AE%A4%E4%B8%80%E6%BB%B4%E8%A1%80%E6%A3%80%E6%B5%8B3000%E4%B8%AA%E8%9B%8B%E7%99%BD%E8%B4%A8.mp4',
      image: '',
    },
  },
};
export const Teams10DataSource = {
  wrapper: { className: 'home-page-wrapper teams1-wrapper' },
  page: { className: 'home-page teams1' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '团队成员' }],
  },
  block: {
    className: 'block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://xhm-test.oss-cn-beijing.aliyuncs.com/xhm_img/fyjc/teammember/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20220401140649.jpg',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>彦蘭</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job',
              children: (
                <span>
                  <p>联合创始人</p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children: (
                <span>
                  <p>国家心理咨询师</p>
                  <p>药膳师</p>
                  <p>中国管理科学研究院特聘研究员</p>
                  <p>国学启蒙教育导师</p>
                </span>
              ),
            },
          ],
        },
      },
      // {
      //   name: 'block1',
      //   className: 'block',
      //   md: 8,
      //   xs: 24,
      //   titleWrapper: {
      //     children: [
      //       {
      //         name: 'image',
      //         className: 'teams1-image',
      //         children:
      //           'https://xhm-test.oss-cn-beijing.aliyuncs.com/xhm_img/fyjc/teammember/%E5%9B%BE%E7%89%872.png',
      //       },
      //       {
      //         name: 'title',
      //         className: 'teams1-title',
      //         children: (
      //           <span>
      //             <p>丁琛教授</p>
      //           </span>
      //         ),
      //       },
      //       {
      //         name: 'content',
      //         className: 'teams1-job',
      //         children: (
      //           <span>
      //             <p>专家顾问团队</p>
      //           </span>
      //         ),
      //       },
      //       {
      //         name: 'content1',
      //         className: 'teams1-content',
      //         children: (
      //           <span>
      //             <span>
      //               <p>2013年入选北京市第九批“海聚工程”</p>
      //               <p>2015年起受聘为复旦大学生命科学学院研究员至今</p>
      //             </span>
      //           </span>
      //         ),
      //       },
      //     ],
      //   },
      // },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://xhm-test.oss-cn-beijing.aliyuncs.com/xhm_img/fyjc/teammember/%E5%9B%BE%E7%89%873.png',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <span>
                    <p>刘沙勤博士</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>专家顾问团队</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children: (
                <span>
                  <p>第二军医大学军医临床医学博士</p>
                  <p>国家二级心理咨询师</p>
                  <p>国家高级健康管理师</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~l1ftwi1an6o',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://xhm-test.oss-cn-beijing.aliyuncs.com/xhm_img/fyjc/teammember/%E5%9B%BE%E7%89%874.png',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>谢衷绣</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job',
              children: (
                <span>
                  <p>专家顾问团队</p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children: (
                <span>
                  <span>
                    <p>健康管理师一级</p>
                    <p>公共营养师一级</p>
                    <p>国家心理咨询师二级</p>
                    <p>婚姻家庭咨询师一级</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~l1ftwjbsdin',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://xhm-test.oss-cn-beijing.aliyuncs.com/xhm_img/fyjc/teammember/%E5%9B%BE%E7%89%875.png',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>郑润祥</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job',
              children: (
                <span>
                  <p>专家顾问团队</p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>中国社会科学院研究生院毕业</p>
                      <p>
                        曾任中国黄金协会黄金投资分析师资格评审专家委员会秘书长
                      </p>
                      <p>厦门大学兼职教授</p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Teams40DataSource = {
  wrapper: {
    className: 'home-page-wrapper content8-wrapper l1furhg3abs-editor_css',
  },
  page: { className: 'home-page content8 l1fuillvdib-editor_css' },
  OverPack: { playScale: 0.3, replay: true },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>合作院校机构</p>
          </span>
        ),
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'content-wrapper l1g2smw46t-editor_css',
    children: [
      {
        name: 'block0',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://xhm-test.oss-cn-beijing.aliyuncs.com/xhm_img/fyjc/teamorg/%E5%9B%BE%E7%89%876.png',
          },
          title: {
            className: 'content8-title',
            children: (
              <span>
                <p>哈佛大学</p>
              </span>
            ),
          },
          content: {
            className: 'content8-content',
            children: (
              <span>
                <p>
                  <br />
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://xhm-test.oss-cn-beijing.aliyuncs.com/xhm_img/fyjc/teamorg/%E5%9B%BE%E7%89%877.png',
          },
          title: {
            className: 'content8-title',
            children: (
              <span>
                <p>约翰霍普金斯大学</p>
              </span>
            ),
          },
          content: {
            className: 'content8-content',
            children: (
              <span>
                <p>
                  <br />
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://xhm-test.oss-cn-beijing.aliyuncs.com/xhm_img/fyjc/teamorg/%E5%9B%BE%E7%89%878.png',
          },
          title: {
            className: 'content8-title',
            children: (
              <span>
                <p>贝勒医学院</p>
              </span>
            ),
          },
          content: {
            className: 'content8-content',
            children: (
              <span>
                <p>
                  <br />
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://xhm-test.oss-cn-beijing.aliyuncs.com/xhm_img/fyjc/teamorg/%E5%9B%BE%E7%89%879.png',
          },
          title: {
            className: 'content8-title',
            children: (
              <span>
                <p>梅奥诊所</p>
              </span>
            ),
          },
          content: {
            className: 'content8-content',
            children: (
              <span>
                <p>
                  <br />
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://xhm-test.oss-cn-beijing.aliyuncs.com/xhm_img/fyjc/teamorg/%E5%9B%BE%E7%89%8710.png',
          },
          title: {
            className: 'content8-title',
            children: (
              <span>
                <p>剑桥大学</p>
              </span>
            ),
          },
          content: {
            className: 'content8-content',
            children: (
              <span>
                <p>
                  <br />
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://xhm-test.oss-cn-beijing.aliyuncs.com/xhm_img/fyjc/teamorg/%E5%9B%BE%E7%89%8711.png',
          },
          title: {
            className: 'content8-title',
            children: (
              <span>
                <span>
                  <span>
                    <p>牛津大学</p>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content8-content',
            children: (
              <span>
                <p>
                  <br />
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block6',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://xhm-test.oss-cn-beijing.aliyuncs.com/xhm_img/fyjc/teamorg/%E5%9B%BE%E7%89%8712.png',
          },
          title: {
            className: 'content8-title',
            children: (
              <span>
                <p>哥伦比亚大学</p>
              </span>
            ),
          },
          content: {
            className: 'content8-content',
            children: (
              <span>
                <p>
                  <br />
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block7',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children:
              'https://xhm-test.oss-cn-beijing.aliyuncs.com/xhm_img/fyjc/teamorg/%E5%9B%BE%E7%89%8713.png',
          },
          title: {
            className: 'content8-title',
            children: (
              <span>
                <p>斯坦福大学</p>
              </span>
            ),
          },
          content: {
            className: 'content8-content',
            children: (
              <span>
                <p>
                  <br />
                </p>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        ©2021-2025 fuyanhealth.com 版权所有<a
          href="https://beian.miit.gov.cn"
          target="_blank"
        >
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<span>沪ICP备2022010439号-3</span>
        </a>
        <span />
        <br />
      </span>
    ),
  },
};
